var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "status-tip" }, [
    _c("img", {
      attrs: { src: require("@/assets/" + _vm.status + ".png"), alt: "" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }