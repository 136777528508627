<template>
  <div class="status-tip">
    <img :src="require('@/assets/' + status + '.png')" alt="" />
  </div>
</template>
<script>
  export default {
    name: 'OrderStatus',
    components: {},
    props: {
      status: {
        type: String,
        default: () => '',
      },
    },
    data() {
      return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  }
</script>
<style lang="scss" scoped>
  // 状态（待审核） 图章
  .status-tip {
    position: absolute;
    top: 50px;
    right: 60px;
    z-index: 2001;
    width: 90px;
    img {
      width: 100%;
    }
  }
</style>
